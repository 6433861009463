var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "table",
        attrs: {
          title: "작업분류표",
          merge: _vm.grid.merge,
          columns: _vm.gridColumns,
          data: _vm.investigation.sopTable,
          gridHeight: _vm.gridHeight,
          editable: _vm.editable && !_vm.disabled,
          hideBottom: true,
          isExcelDown: false,
          filtering: false,
          isFullScreen: false,
          columnSetting: false,
        },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                _c(
                  "span",
                  {
                    class:
                      props.row[col.name] === "O" ? "text-primary" : "text-red",
                  },
                  [_c("b", [_vm._v(_vm._s(props.row[col.name]))])]
                ),
              ]
            },
          },
          {
            key: "customHeader",
            fn: function ({ props, col }) {
              return [_c("q-img", { attrs: { src: `${col.src}` } })]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }